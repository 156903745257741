import axios from 'axios'
import store from '@/store'

axios.defaults.withCredentials = true

const api = axios.create({
    baseURL: process.env.VUE_APP_FULL_URL_API,
    headers: {}
})

function addHeaders(config) {
    //example config.headers.http_content_language = 'pt_BR'
    return config
}

function handleRequestSuccess(req) {
    // TODO
    return req
}

function handleRequestError(err) {
    // TODO
    if (err.response && err.response.status === 401) {
    // new AuthService().logout()
    // window.reload()
    } else throw err
}

function handleResponseSuccess (req) {
    if (req.data.type && req.data.message) {
        store.dispatch('alert/setAlert', req.data)
    }
    return req
}

function handleResponseError (err) {
    store.dispatch('alert/setAlert', err.response.data ? err.response.data : err)
    if (err.response && err.response.status === 401) {
        // new AuthService().logout()
        // window.reload()
    } else throw err
}

// Add a request interceptor
api.interceptors.request.use(handleRequestSuccess, handleRequestError, addHeaders)
// Add a response interceptor
api.interceptors.response.use(handleResponseSuccess, handleResponseError)

export const clientAPI = api