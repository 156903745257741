<template>
  <main>
    <div>
      <router-view/>
    </div>
  </main>
</template>

<script>
export default {
    name: 'App',
    components: {},
    created: function(){
    }
}
</script>

<style>
#app {
  margin-top: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
