function resolveUrlBrand(){
    const currentUrl = window.location.href
    const brand = currentUrl.match(/umbro|fila|newbalance/) || ['umbro']
    return brand[0]
}

export function ConfigImages(imgPath,imgName,imgType) {
    let urlBrand = resolveUrlBrand()
    let urlImage = require('@/assets/images/'+urlBrand+'/'+imgPath+'/'+imgName+'.'+imgType)

    return {
        imgUrl: urlImage,
        imgBackGorund: `url("${urlImage}")`
    }
}

export function ConfigExternals() {
    let urlBrand = resolveUrlBrand()
    let urlSite = 'https://www.'+urlBrand+'.com.br'

    return {
        externalUrlSite: urlSite
    }
}

export function configColors() {
    let urlBrand = resolveUrlBrand()
    let themeColor = '#000000'
    switch (urlBrand) {
    case 'umbro':
        themeColor = '#000000'
        break
    case 'fila':
        themeColor = '#19191A'
        break
    case 'newbalance':
        themeColor = '#000000'
        break
    }
    
    return {
        themeColor
    }
}