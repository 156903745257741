import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'floating-vue/dist/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './routers'
import i18n from './i18n'
import FloatingVue from 'floating-vue'
import VueTheMask from 'vue-the-mask'

export const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(store)
    .use(FloatingVue)
    .use(VueTheMask)
    .mount('#app')

import 'bootstrap/dist/js/bootstrap.js'