import { createRouter, createWebHistory } from 'vue-router'
import Voucher from '../views/Voucher.vue'

const routes = [
    {
        path: '',
        name: 'home',
        component: Voucher,
        meta: {
            freeAccess: true,
        }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router

export default router