import * as types from './mutation-types'

export default {
    [types.SET_ALERT] (state, payload) {
        state.alert = payload.message
    },
    [types.DROP_ALERT] (state) {
        state.alert = null
    },
}
